import { formatNumber as libFormatPhone } from 'libphonenumber-js';
import Flatpickr from 'flatpickr';
import pluralizeFunction from 'pluralize';
import has from 'lodash/has';
import isObject from 'lodash/isObject';
import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';
import { onBeforeUnmount, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useRootStore } from '@/user/stores/root';
import { MyCompanyQuery } from '@/user/queries/user.query.graphql';
import { v4 as uuid4 } from 'uuid';
import Cookies from 'js-cookie';
import round from 'lodash/round';
import { format as formatDate } from 'date-fns/format';
import { parseISO } from 'date-fns';
import { onBeforeRouteLeave } from 'vue-router';

export const logError = function (e) {
    if (console && e !== undefined) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export const hasGraphQlError = function (e) {
    return e && e.graphQLErrors && e.graphQLErrors[0];
};

export const formatPhone = function (phone, format = 'NATIONAL') {
    // empty phone number or not an object with the required key
    if (!phone || !phone.phoneNumber) {
        return phone;
    }

    let str = phone.phoneNumber;
    if (phone.extension) {
        str += ' x'+phone.extension;
    }

    return libFormatPhone(str, 'CA', format);
};

export const date = function (_date, format = 'M j, Y') {
    if (null === _date) {
        return null;
    }

    if (!(_date instanceof Date)) {
        _date = Flatpickr.parseDate(_date, 'Y-m-d');
    }

    return Flatpickr.formatDate(_date, format);
};

export const dateTime = function (_dateTime, format = 'F j, Y \\a\\t h:iK') {
    if (null === _dateTime) {
        return null;
    }

    if (!(_dateTime instanceof Date)) {
        _dateTime = Flatpickr.parseDate(_dateTime, 'Y-m-d H:i:s');
    }

    return Flatpickr.formatDate(_dateTime, format);
};

export const reportingPeriodLabel = function (period, short = false) {
    const start = parseISO(period.startDate);
    const end = parseISO(period.endDate);
    let label;
    const fullFormat = (short ? 'MMM' : 'MMMM') + ' d, yyyy';

    if (start.getFullYear() === end.getFullYear()) {
        label = `${ formatDate(start, (short ? 'MMM' : 'MMMM') + ' d') } – ${ formatDate(end, fullFormat) }`;
    } else {
        label = `${ formatDate(start, fullFormat) } – ${ formatDate(end, fullFormat) }`;
    }

    return label;
};

/**
 * Accepts dollars and divides by 100 before formatting.
 */
export const money = function (value, decimals = 2) {
    const price = parseFloat(value / 100);

    // from what I'm reading, Android doesn't fully support the locales and options
    try {
        return price.toLocaleString('en-CA', {
            style: 'currency',
            currency: 'CAD',
            maximumFractionDigits: decimals,
        });
    } catch (e) {
        return '$'+price.toFixed(decimals);
    }
};
/**
 * Accepts dollars and rounds before using.
 */
export const moneyWithoutSymbol = function (value, decimals) {
    return round(value, decimals).toLocaleString(
        undefined,
        { minimumFractionDigits: decimals, maximumFractionDigits: decimals },
    );
};

export const pluralize = function (string, count) {
    return pluralizeFunction(string, count);
};

export const upperFirstFilter = function (string) {
    return upperFirst(lowerCase(string));
};

export const hasVuelidateProp = function (v, key) {
    return has(v, key);
}

export const vuelidateValue = function (v, key) {
    if (!hasVuelidateProp(v, key)) {
        return true;
    }

    return !v[key].$invalid;
};

export const omitTypename = function (obj) {
    for (const key in obj) {
        if (isObject(obj[key])) {
            omitTypename(obj[key]);
        } else if ('__typename' === key) {
            delete obj[key];
        }
    }

    return obj;
};

export const companyRoles = function (projectCompanies, excludeRole = null) {
    return projectCompanies.reduce((roles, projectCompany) => {
        if (excludeRole && projectCompany.projectRole === excludeRole) {
            return roles;
        }

        if (!roles[projectCompany.projectRole]) {
            roles[projectCompany.projectRole] = [];
        }

        roles[projectCompany.projectRole].push(projectCompany.companyId);

        return roles;
    }, {});
};

export const addEditedWatcher = function (state, edited, variable) {
    watch(
        variable,
        () => {
            if (state.value.matches('ready')) {
                edited.value = true;
            }
        },
        { deep: true, flush: 'sync' },
    );
};
/**
 * Adds a leave confirmation dialog to the window and the Vue router when there are unsaved changes.
 *
 * @param {Ref} edited Must be Vue ref/reactivity object.
 * @param {Ref} isSaved Must be Vue ref/reactivity object.
 */
export const addLeaveConfirmation = (edited, isSaved) => {
    onBeforeRouteLeave((to, from, next) => {
        const msg = 'Are you sure you want to leave? You have unsaved changes.';
        if (edited.value && !isSaved.value && !confirm(msg)) {
            return next(false);
        }

        next();
    });

    const unload = (e) => {
        if (edited.value && !isSaved.value) {
            e.preventDefault();
        }
    };

    window.addEventListener('beforeunload', unload);
    onBeforeUnmount(() => {
        window.removeEventListener('beforeunload', unload);
    });
};

export const switchCurrentCompany = function (newCompanyId) {
    return new Promise((resolve) => {
        const rootStore = useRootStore();

        if ('ALL' === newCompanyId) {
            rootStore.setCurrentCompany('ALL');

            resolve();

            return;
        }

        // load & set the company
        const { onResult, onError } = useQuery(MyCompanyQuery, { companyId: newCompanyId });
        onResult(({ data: { MyCompany } }) => {
            rootStore.setCurrentCompany(MyCompany);

            resolve();
        });
        onError(() => {
            alert('There was a problem changing the company.');
            window.location.reload();
        });
    });
};

export const prepareAdjusters = function (adjusters) {
    return adjusters
        // don't save adjusters without a name, email, or phone number
        .filter((a) => a.name || a.email || a.phoneNumber)
        .map((a) => ({
            ...a,
            projectCompanyAdjusterId: uuid4(),
        }))
};

export const currentCompanyCookie = {
    name: 'current-company-id',

    set (company) {
        const cookieConfig = { expires: 365, secure: true, sameSite: 'strict' };

        if (company === 'ALL') {
            Cookies.remove(this.name, cookieConfig);

            return;
        }

        Cookies.set(this.name, company.companyId, cookieConfig);
    },

    get () {
        return Cookies.get(this.name);
    },
};

export const tripHasFilesOrNotes = (trip) => {
    return trip.tripMaterials.some(tripMaterial => tripMaterial.files.length > 0) || trip.notes.length > 0;
};
